import React, { useDeferredValue, useEffect, useRef } from 'react';
import { useLocale } from '@unisporkal/localization';
import { useUser } from '@unisporkal/user';
import { userExperience, ga4Track } from '@unisporkal/ga4';
import appendEcommerceTestVariation from '../../../utils/appendEcommerceTestVariation';
import { useOutsideAlerter, useSearchBar } from '../../../hooks';
import { AutoSuggest, SearchBar, SearchBox, SearchByImage } from '../../shared';
import { DesktopMediaFilter, MobileMediaFilter } from '../index';
import styles from './GettySearchBar.module.scss';

const placeholders = {
  searchbyimage: 'search_box.search_bar_similar_images_placeholder',
  searchbyasset: 'search_box.search_bar_similar_images_placeholder',
  morelikethis: 'search_box.search_bar_similar_images_placeholder',
  image: 'search_box.search_all_images',
  'image.creative': 'search_box.search_creative_photos_and_images',
  'image.creative.photography': 'search_box.search_creative_photos_and_images',
  'image.creative.illustration': 'search_box.search_creative_photos_and_images',
  'image.creative.illustration.eps':
    'search_box.search_creative_photos_and_images',
  'image.editorial': 'search_box.search_editorial_photos',
  imagefilm: 'search_box.search_creative_image_and_film',
  film: 'search_box.search_all_videos',
  'film.creative': 'search_box.search_creative_footage_and_video',
  'film.editorial': 'search_box.search_editorial_footage_and_video',
};

const GettySearchBar = () => {
  const containerRef = useRef(null);
  useOutsideAlerter(containerRef);
  const searchBar = useSearchBar();
  const locale = useLocale();
  const user = useUser();
  const { inEditorialTest, inExperience } = user.imageFilmGrid;
  const showMobileMediaFilter = useDeferredValue(
    searchBar.options.mobileMediaFilter && searchBar.inFocus
  );

  const convertCamelToSnakeCase = (str) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

  useEffect(() => {
    if (inEditorialTest) {
      const variation = inExperience ? 'expA' : 'control';
      const ga3Value = `gi_srp_editorial_imagefilm_grid-${variation}`;
      appendEcommerceTestVariation(ga3Value);

      const ga4Event = userExperience({
        test_type: 'ab_test',
        test_variation: `gi_srp_editorial_imagefilm_grid_${convertCamelToSnakeCase(
          variation
        )}`,
      });
      ga4Track(ga4Event);
    }
  }, []);

  const autoSuggestOptions = {
    endpoint:
      'https://as.gettyservices.com:443/GettyImages.Autocomplete.KeywordService.Service/KeywordService1/Suggestedkeywords',
    siteLocale: searchBar.locale || locale,
    searchAssetType: searchBar.searchParameters.assettype || 'image',
    searchAssetFamily: searchBar.searchParameters.family || 'any',
  };

  const showOverlay = () => {
    if (searchBar.options.overlayOnFocus && searchBar.inFocus) {
      return true;
    }
    if (
      searchBar.options.overlayOnFocusAndDocked &&
      searchBar.inFocus &&
      searchBar.peekyScrollMode === 'docked'
    ) {
      return true;
    }
    return false;
  };

  return (
    <SearchBar>
      <>
        {showOverlay() && <div className={styles.overlay} />}
        <div
          className={styles.container}
          ref={containerRef}
        >
          <div className={styles.holder}>
            <SearchBox placeholders={placeholders} />
            <DesktopMediaFilter />
            <SearchByImage />
          </div>
          {showMobileMediaFilter && (
            <div className={styles.mediaFilter}>
              <MobileMediaFilter />
            </div>
          )}
          <AutoSuggest options={autoSuggestOptions} />
        </div>
      </>
    </SearchBar>
  );
};

export default GettySearchBar;
